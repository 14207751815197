import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/jpeg/d1.jpg";
import Recovery from "../../sections/rhino/rhinore.js";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import SectionOne from '../../sections/countersection/countersection';
import Newarrow from '../../assets/image/jpeg/enter.svg'





const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;






const Arrow = styled.div`


width: 1.8em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
 

}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 2em;
background-color: #999;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #999;
font-size: 1em;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 16px;
}

`;



const Secondtext = styled.div`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;

      margin-bottom: 0.4em;
     
      color: #111;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 26px;
   
      }

`;


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const MesoCccontentPage = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section py={4}>
      <Container className="pt-lg-5 pb-lg-5">

          <Row>
              <Col>
                <Iwrap>


<Itext as="h2">Mesotherapy For Hair Growth London</Itext>
</Iwrap>
      <Secondtext>
      What is Mesotherapy For Hair Growth Treatment?

      </Secondtext>
              </Col>
          </Row>
        <Row className="align-items-center">
          <Col lg="6" className="mb-4 mb-lg-0">
            <div
              className="pl-0"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-delay="200"
              data-aos-once="true"
            >
              <ImgStyled src={imgMobile} alt="prp beard restoration" className="img-fluid" />
            </div>
          </Col>
          <Col lg="6" md="9">
            <Box className=" pt-lg-0">
          
              <Text>

              It is not surprising that Mesotherapy has a variety of advantages such as rejuvenating and brightening the skin but it is also beneficial for those suffering from hair loss and thinning. With most patients seeing a remarkable reduction in hair fall. <br /> <br />
Mesotherapy is a non-surgical technique using specifically formulated cocktails of vitamins, minerals and amino acids that get delivered into the scalp. The infusion of these ingredients directly targets the causes of hair loss through stimulating blood circulation, delivering the right nutrients and balancing hormone levels around the follicles. The main factors of hair loss can include: genetics, hormonal imbalances, decreased blood circulation, underlying health conditions, and a lack of key nutrients. <br /> <br />

 





              </Text>
            
            
             <br /> <br />
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment Online
                
                
                </Bookbutton> </a>
           
            </Box>
          </Col>
        </Row>
        <br /> <br />
        <Row>
            <Col>
            <Secondtext>
            How does Mesotherapy for Hair Loss work?
            </Secondtext>

            <Text>
            Mesotherapy treatment aims to deliver the necessary components into the epidermis layer of the scalp encouraging cell growth and improving blood circulation. The bespoke blend of specialised cocktails is inserted using a thin needle directly to the scalp, in the targeted tissue.<br /> <br />
 
 Mesotherapy for hair treatment London, targets specific areas of the scalp where the hair is thinning or balding. Its unique combination promotes and restores hair growth in existing hair follicles. It can help increase microcirculation and provide vital nutrients to hair follicles, promoting hair growth and as a result healthier, thicker and fuller looking hair.  <br /> <br />
 
            </Text>

            <Secondtext>
            What are the benefits of Mesotherapy for Hair Loss?
            </Secondtext>
            <Text>
            -       Mesotherapy delivers active nutrients straight to the hair follicles promoting scalp rejuvenation and hair regrowth  <br /> <br />
-       The nutrients are directly delivered deep into the dermis layer of the scalp, ensuring effective and stimulating results  <br /> <br />
-       Helps hair growth in patients with alopecia  <br /> <br />
-       Stimulates blood circulation  <br /> <br />
-       No recovery time needed <br /> <br />
-       Through mesotherapy injections, a healthy environment is maintained for hair growth, effectively lowering the risk of scalp irritation 

            </Text>
            </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default MesoCccontentPage;
